<template>
    <div>
        <div class="vertical-wrapper" style="margin: 5pt">

            <md-field>
                <label for="">Username</label>
                <md-input v-model="username" v-on:keyup.enter="register"></md-input>
            </md-field>
            <md-field>
                <label for="">E-Mail</label>
                <md-input v-model="email" v-on:keyup.enter="register"></md-input>
            </md-field>

            <md-field>
                <label for="">Password</label>
                <md-input type="password" v-model="pw" v-on:keyup.enter="register"></md-input>
            </md-field>
            <md-field>
                <label for="">Password wiederholen</label>
                <md-input type="password" v-model="pw1" v-on:keyup.enter="register"></md-input>
            </md-field>

            <md-button class="md-primary md-raised" style="margin: 0" v-on:click="register">Registrieren</md-button>
        </div>


    </div>
</template>
<script>
export default {
    name: 'login-view',
    methods:{
        register(){
            if(this.pw == this.pw1 && this.pw != '')
                this.$socket.emit('register', { email: this.email, name: this.username, password: this.pw })
            else{
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Registrieren fehlgeschlagen',
                    text: 'Passwörter müssen übereinstimmen'
                })
            }
        }
    },
    
    sockets:{
        'register-failed': function () {
            this.$swal({
                icon: 'error',
                title: 'Registrieren fehlgeschlagen',
                text: 'E-Mail oder Nutzername exisitiert bereits',
            })
        },
        'register-success': function({token}){
            this.$swal({
                icon: 'info',
                title: 'Registrieren erfolgreich',
                text: 'Bitte bestätige deine E-Mail Adresse',
            })
            this.$store.commit('set-token', token)
            this.$router.push({name: 'thermostat-view' })
        }
    },
    data(){
        return{
            loginAtempt: false,
            username: '', 
            email: '',
            pw: '',
            pw1: '',
        }
    }
}
</script>