<template>
    <!--Thermostat-->
    <div class="vertical-wrapper">
        <div class="horizontal-wrapper" style="margin-top: 5pt">
            <div></div>
            <div style="width: 270px; height:180px;flex: 0 0 270px;">
                <round-slider flex="1 0 0" ref="slider1" v-model="thermostatSetpoint" start-angle="0" end-angle="+180"
                    line-cap="round" radius="180" :min="thermostatMin" :max="thermostatMax" :rangeColor="sliderPathColor" />
                
            </div>
            <div></div>
        </div>
        <div style="margin-top: 0; z-index: 100;" v-on:click="openTimePicker">
            <h4 style="margin-bottom: 5px">Hand Thermostat Dauer:</h4>
            <input type="text" style="text-align: center; font-size: 13pt;" :value="$moment(handTime).format('HH:mm')">
        </div>
        <div style="margin-top: 20px;">
            <md-button class="md-primary" v-on:click="setHandControl">Hand</md-button>
        </div>
        <datetime type="time" title="Hand Thermostat Dauer" style="flex: 0 0 0" class="theme-green hide-input"
            ref="handtimepicker" v-model="handTime" />
    </div>
</template>
<script>
const convert = require('color-convert');

export default{
    name: 'thermostat-control-view',
    computed: {
        sliderObjects() {
            // add other slider refs also here, if you have more
           
            return [this.$refs.slider1.instance];
        },
        sliderPathColor() {
            const colorMax = 10;
            const colorMin = 180;

            const sliderMax = parseFloat(this.thermostatMax);
            const sliderMin = parseFloat(this.thermostatMin);
            const m = (colorMax - colorMin) / (sliderMax - sliderMin)

            const t = colorMax - (m * sliderMax)
            //console.log(m, t);
            const x = this.thermostatSetpoint
            const color = m * x + t
            //const color = (-0.00000055 * Math.pow(x,5) + 190)

            //const color = 23194 * Math.pow(x, -1,92)
            //const color = 557.75 * Math.pow(Math.E, -0.092 * x) + 5
            return '#' + convert.hsl.hex(color, 98, 50)
        },
        handTime: {
            set(value) {
                const t = this.$moment(value)
                this.handTimeValue = t.hour() * 60 + t.minute()
            },
            get() {

                return this.$moment().set('hour', parseInt(this.handTimeValue / 60)).set('minutes', this.handTimeValue % 60).format('YYYY-MM-DDTHH:mm:ssZ')
            }
        },

        token() {
            return this.$store.getters.token
        },
       
        date() {
            return this.$store.getters.getDate
        },
        thermostatSetpoint:{
            get(){
                return this.$store.getters.getThermostatSetpoint
            },
            set(value){
                this.$store.commit('set-thermostat-setpoint', value)
            }
        },
        thermostatMax() {
            return this.$store.getters.getThermostatMax
        },
        thermostatMin(){
            return this.$store.getters.getThermostatMin
        },
        selectedRoomId() {
                return this.$store.getters.getSelectedRoomId;
        },
    },
    methods: {
        resizeHandler: function () {
            if(!this.sliderObjects.length)
                return
            this.sliderObjects.forEach((instance) => {
                var radius = Math.round(instance.control.parent().width() / 2);
                instance.option("radius", radius);
            });
        },
        openTimePicker(e) {
            this.$refs.handtimepicker.open(e)
        },
        setHandControl() {
            console.log('hand control', { token: this.token, room: { id: this.selectedRoomId }, thermostat: { value: this.thermostatSetpoint, duration: this.handTimeValue } });
            this.$socket.emit('set-thermostat-hand', { token: this.token, room: { id: this.selectedRoomId }, thermostat: { value: this.thermostatSetpoint, duration: this.handTimeValue } })

        }
    },
    mounted() {
        window.addEventListener("resize", this.resizeHandler);
        // initial time resize event won't be triggered, so call it manually once
        this.resizeHandler();
       
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },
    data(){
        return{
            handTimeValue: 120,

        }
    }
}
</script>