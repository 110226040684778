<template>
    <div class="vertical-wrapper">
        <div class="horizontal-wrapper res" style="flex: 0 0 auto; margin-left: 20pt; margin-right: 20pt">
            <h1 style="font-size: 42pt;">
                <span style="color: #03ac13">Heating </span>
                <span>Plan</span>
                <span style="color: #03ac13">'t</span>
            </h1>
            <div style="flex: 3 0 0 ">

            </div>
            <div class="horizontal-wrapper res" style="flex: 0 0 auto; margin-left: 10pt">
                <md-button class="md-raised md-primary" style="margin: auto 10pt" v-on:click="$router.push({ name: 'login-view'})">Login</md-button>

                <md-button class="md-raised" style="margin: auto 10pt" v-on:click="$router.push({ name: 'register-view'})">Registrieren</md-button>
            </div>
        </div>
        <div class="horizontal-wrapper" style="margin-left: 20pt">
            <h2>Heizungssteuerung über einen dynamischen Kalender</h2>
        </div>
       <div>

       </div>
    </div>
</template>
<script>
export default{
    name: 'index',
    beforeRouteEnter(to, from, next){
        next((vm) => {
            const token = vm.$cookies.get('user-token');
            if(token && token != '')
                vm.$router.push({name: 'login-view'})
        })
    }
} 
</script>