<template>
    <div>

        <h2>Einstellungen</h2>
        <div class="vertical-wrapper settings-menu" style="margin-top: 20px">
            <router-link v-ripple :to="{name: 'settings-user-view'}" class="item">User</router-link>

            <router-link v-ripple :to="{name: 'settings-organisation-view'}" class="item">Organisationen</router-link>
            <router-link v-ripple :to="{name: 'settings-rooms-view'}" class="item">Heizräume</router-link>

            <router-link v-ripple :to="{name: 'settings-heating-plans-view'}" class="item">Heizpläne</router-link>
            <router-link v-if="false" v-ripple :to="{name: 'settings-devices-view'}" class="item">Geräten</router-link>

            <router-link v-ripple :to="{name: 'settings-impressum-view'}" class="item">Informationen</router-link>

        </div>
    </div>
</template>
<script>
export default {
    name: 'menu-view'
}
</script>
<style>
.settings-menu a.item{
    padding-top: 10pt;
    padding-bottom: 10pt;
    font-size: 15pt;
    margin: 5pt;
    box-shadow: 0 2px 3px 0px rgb(0 0 0 / 20%);
    background-color: white;
}
.settings-menu a.item:first-child {
}
</style>