<template>
    <div class="horizontal-wrapper" style="margin: 10pt">
        <div></div>
        <div v-if="type == 'Number'" style="flex: 0 0 auto">
            <md-field>
                <label for="">{{name}}</label>
                <md-input :value="value" @input="input"></md-input>
            </md-field>
        </div>

        <div v-if="type == 'time'" style="flex: 0 0 auto" class="vertical-wrapper">
            <p style=" margin: 0; font-size: 9pt; text-align: left;">{{name}}</p>
            <cvue-datetime style="flex: 0 0 auto; margin: auto;" :title="name" type="time" :auto="false" class="theme-green larger" :over-24-hours="true" v-model="copyValue" :dateModifier="dateModifier" :date="date"></cvue-datetime>
        </div>
        <div></div>

    </div>
</template>
<script src="">
import CVueDateTime from './vue-timepicker/src/Datetime.vue' 

export default{
    name: 'event-input',
    props: {
        type:String, name:String, value:[String, Number], 
    },
    components: {
        'cvue-datetime': CVueDateTime
    },
    computed:{
        date(){
            return this.$store.getters.getDate
        },
        isoDate: {
            get(){
                if (!this.value)
                    return ''

                const d = this.$moment(this.date.format('YYYY-MM-DD'))
                //const [hours, minutes] = this.value.split(':').map(e => parseInt(e))
                //this.dateModifier = parseInt(this.value / 1440 )

                const value = Math.abs(this.value) % 1440
                d.set('hour', parseInt((value ) / 60))
                d.set('minute', value % 60)

                
                return d.format('YYYY-MM-DDTHH:mm:ssZ')
            },
            set(value){
                const time = this.$moment(value).format('HH:mm')
                const [hours, minutes] = time.split(':').map(e => parseInt(e))
                const days = this.$moment(value).diff(this.$moment(this.$moment().format('YYYY-MM-DD ') + time), 'days')
                this.$emit('input', days * 1440 + (hours * 60 + minutes))

            }

        },
        copyValue:{
            get(){
                if(this.type == 'time' && this.value == '')
                    return 0

                return parseInt(this.value)
            },
            set(value){
                this.$emit('input', value)
            }
        }
    },
    methods:{
        input(e){
            this.$emit('input', e)
        }
    },
    data(){


        return{
            dateModifier: 0
        }
    }
}
</script>
<style>
.larger .vdatetime-input{
    font-size: 13pt;
}
</style>