import Vue from 'vue'
import App from './App.vue'


import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial);
//Own Components
import VueMdSelectionList from '../src/components/md-selection-list.vue'
Vue.component('md-selection-list', VueMdSelectionList)

const moment = require('moment')
require('moment/locale/de')
Vue.use(require('vue-moment'), {
  moment
})
import VueUUID from 'vue-uuid'
Vue.use(VueUUID);

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
Vue.component('datetime', Datetime);
Vue.use(Datetime)

import Ripple from 'vue-ripple-directive'
Vue.directive('ripple', Ripple);

//Socket.io
import IO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
const devServer = 'http://localhost:5003'
//const devServer = 'http://192.168.14.107:6500/'

//const devServer = 'https://ebh.frappgmbh.de'
const inProduction = !(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && typeof console !== 'undefined')

const optionsVueIO = {
  connection: inProduction ? IO() : IO(devServer)
}
Vue.use(new VueSocketIO(optionsVueIO));

//import VueGoogleCharts from 'vue-google-charts'
//Vue.use(VueGoogleCharts)

//Cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('30d')


import createStore from '@/store/index.js'

import VueRouter from 'vue-router'
import myrouter from '@/router/index.js'
Vue.use(VueRouter)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#03ac13',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);

import RoundSlider from 'vue-round-slider'
Vue.use(RoundSlider)

import './assets/layout.css'
import './assets/theme.css'
import './registerServiceWorker'

import './registerServiceWorker.js';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store: createStore(),
  router: myrouter,
}).$mount('#app')
