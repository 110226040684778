<template>
    <div class="vertical-wrapper">
        <setting-header title="Organisation" :route="{name: 'settings-menu-view'}"/>

        <div v-if="organisations.length" class="vertical-wrapper" style="margin: 0 5pt; overflow-y: scroll;">
            <md-field>
                <label for="">Organisationen</label>
                <md-select v-model="selectedOrganisationsId" v-on:selected="stopInventation">
                    <md-option v-for="o in organisations" :key="o.key" :value="o.id">{{o.name}}</md-option>
                </md-select>
            </md-field>

            <div class="vertical-wrapper">
                <h4>Organisation beitreten</h4>
                <qrcode-vue v-if="inventationActive" :value="inventationUrl" :size="150" level="H" />
            
                <h4>Einladung bearbeiten</h4>
                <md-switch class="md-primary" v-model="inventation.isAdmin" v-on:change="stopInventation" style="flex: 0 0 auto">Administrator</md-switch>
                <h5>Räume</h5>
                <md-selection-list :items="inventation.rooms" v-on:item-selection-change="stopInventation" :editable="false" style="min-height: 200pt"></md-selection-list>
                <div class="horizontal-wrapper">
                    <div></div>

                    <md-button class="md-raised md-primary" v-on:click="requestInventation">Erstellen</md-button>
                    <div v-if="inventationUrl != ''" style="flex: 0.5 0 0"></div>
                    <md-button v-if="inventationUrl != ''" class="md-raised md-primary" v-on:click="copyURL">Kopieren</md-button>
                    <div></div>

                </div>
                <div style="flex: 0 0 50px"></div>
            </div>
        </div>
        <div v-else style="flex: 1 0 0" class="vertical-wrapper">
            <div></div>
            <h2 class="empty-state">keine Organisation beigetreten</h2>
            <div style="flex: 2 "></div>
        </div>
        <router-view></router-view>
        <md-button class="md-fab md-primary md-invert" style="position: absolute; right: 5px; bottom: 65px; 
                            height: 35pt; width: 35pt;" v-on:click="addOrganisation">
            <md-icon :md-src="require('../../assets/plus-solid.svg')" style="fill: white;"></md-icon>
        </md-button>
    </div>
</template>
<script>
import settingHeaderVue from '../../components/setting-header.vue';
import QrcodeVue from 'qrcode.vue'

export default {
    name: 'settings-organisation-view',
    components:{
        'setting-header': settingHeaderVue,
        QrcodeVue,
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        rooms(){
            return this.$store.getters.getRooms
        }
    },
    beforeRouteEnter(to, from, next){
        next((vm) => {
            vm.$socket.emit('get-organisations', {token: vm.token})
            vm.$socket.emit('get-rooms', { token: vm.token })
        })
    },
    sockets: {
        'set-organisations': function({organisations}){
            this.organisations = organisations

            const activeOrganisation = this.organisations.find(e => e.active)
            if(activeOrganisation)
                this.selectedOrganisationsId = activeOrganisation.id
        },
        'set-organisation-inventation':function({code}){
            this.inventationActive = true;

            this.inventationUrl = `https://ebh.frappgmbh.de/settings/organisation/add-organisation/${code}`
        },
        'add-user-to-organisation-success': function({name}){
            this.$swal.fire({
                title: 'Willkommen',
                text: `Sie wurden zu ${name} hinzugefügt`,
                icon: 'success',
            })
        }
    },
    methods:{
        addOrganisation(){
            this.$router.push({ name: 'add-organsiation-popup-view'})
        },
        requestInventation(){
            this.$socket.emit('get-organisation-inventation', {token: this.token, inventation: this.inventation})
        },
        stopInventation(){
            this.inventationActive = false
        },
        async copyURL() {
            try {
                await navigator.clipboard.writeText(this.inventationUrl);
                alert('Copied');
            } catch ($e) {
                alert('Cannot copy');
            }
        }
    },  
    mounted(){
        this.inventation.rooms = this.rooms
    },
    data(){
        return{
            selectedOrganisationsId: 0,
            organisations: [],
            inventationUrl: '',
            inventationActive: false,
            inventation: {
                isAdmin: false,
                rooms: []
            }
        }
    }
}
</script>