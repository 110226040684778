<template>
    <div class="vertical-wrapper" style="overflow: scroll; margin: 0 5pt;">
        <div :class="{'hide':!rooms.length}" class="vertical-wrapper">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-field >
                    <label for="">Room</label>
                    <md-select v-model="selectedRoomId" v-on:md-selected="changeRoomID">
                        <md-option v-for="r in rooms" :key="r.key" :value="r.id">{{r.name}}</md-option>
                    </md-select>
                </md-field>
            </div>
            <div style="flex: 0 0 auto">
                <h3>Ist Temperatur</h3>
                <h2>{{istTemperatur}}°C</h2>
            </div>
            <div class="navbar">
                <router-link v-ripple="'rgba(03, 172, 13, 0.35)'" :to="{ name: 'thermostat-control-view'}">Thermostat</router-link>
                <router-link v-ripple="'rgba(03, 172, 13, 0.35)'" :to="{ name: 'thermostat-chart-view'}">Trend</router-link>
                <router-link v-ripple="'rgba(03, 172, 13, 0.35)'" v-if="isadmin" :to="{ name: 'thermostat-details-view'}">Details</router-link>

            </div>
            <router-view></router-view>

        </div>
        
        <div :class="{'hide':rooms.length}" class="vertical-wrapper">
            <div></div>
            <h3>Keiner Organisation beigetreten</h3>
            <h3>Keine Räume angelegt</h3>
            <div></div>
        </div>

    </div>
</template>
<script type="text/javascript">
import { renderTime } from '../../components/vue-timepicker/src/util'



export default {
    name: 'thermostat-view',
    computed: {
       
        token(){
            return this.$store.getters.token
        },
        rooms(){
            const rooms = this.$store.getters.getRooms
            return rooms 
        },
     

        date(){
            return this.$store.getters.getDate
        },
        isadmin(){
            return this.$store.getters.isadmin
        },
        selectedRoomId: {
            get(){
                return this.$store.getters.getSelectedRoomId;
            },
            set(value){
                this.$store.commit('set-selected-room-id', value)
            }
        }
        
    },
    methods:{
        
      
        changeRoomID(){
            this.$store.commit('set-selected-room-id', this.selectedRoomId)
            this.$socket.emit('get-thermostat-details', {token: this.token, room:{id: this.selectedRoomId}, date: this.date})
            
        },
       
        openTimePicker(e){
            this.$refs.handtimepicker.open(e)
        },

        setHandControl(){
            this.$socket.emit('set-thermostat-hand', {token: this.token, room:{id: this.selectedRoomId}, thermostat: {value: this.sollTemperatur, duration: this.handTimeValue} })
           
        }
    },
    sockets: {
     
        'set-thermostat-details':function({value, min, max}){
            //console.log(value, min, max);
            this.sollTemperatur = value

            this.maxTemperatur = max;
            this.minTemperatur = min;


            this.$store.commit('set-thermostat-setpoint', value)
            this.$store.commit('set-thermostat-min', min)
            this.$store.commit('set-thermostat-max', max)

        },
        'set-thermostat-is':function({isTemp}){
            this.istTemperatur = isTemp
           // this.$store.commit('set-thermostat-setpoint', isTemp)
        },
     
        'set-thermostat-hand-success':function({roomname, temperatur, time}){
            this.$swal.fire({icon: 'success', title: 'Sollwert geändert', text: `${roomname} auf ${temperatur}°C für ${renderTime(parseInt(time))}h`})
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$socket.emit('get-rooms', {token: vm.token})
            
            vm.timer = setInterval(() => {
                vm.$socket.emit('get-thermostat-details', {token: vm.token, room:{id: vm.selectedRoomId}, date: vm.date})
            }, 1 * 60 * 1000) 


                vm.$router.push({ name: 'thermostat-control-view'})
             
        })
    },
    beforeRouteLeave(to, from, next){
        if(this.timer)
            clearInterval(this.timer)
        next()
    },
    data(){
        return{
            istTemperatur: 17.5,
            sollTemperatur: 21.5,
            minTemperatur: 10,
            maxTemperatur: 50,


            timer: 0,
            handTimeValue: 120,

           
            
        }
    },
  
}
</script>

<style>
.md-tabs-navigation{
    display: flex;
    flex-direction: row;
}
.md-tabs-navigation > .md-button{
    flex: 1 0 0; 
}
.md-tabs.md-theme-default .md-tabs-navigation .md-button{
    background-color: #fafafa;
}
.rs-svg-container svg{
    z-index: 1;
}
</style>