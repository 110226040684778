<template>
    <div class="vertical-wrapper">
        <h2>Event</h2>
        <date-header v-on:change="reloadEvents"></date-header>
      
        <div class="vertical-wrapper" @touchstart="touchStartMethod" @touchmove="touchMove" :style="` transform: translate( ${marginCalendar * 1.2}px,0 ); overflow-y: scroll`" >
            <div v-for="s in schedule" :key="s.key" v-ripple style="margin: 5pt; flex: 0 0 auto;" class="horizontal-wrapper" 
            v-on:click="$router.push({name: 'event-popup-view', params: {eventId: s.ID}})">
                <div style="flex: 0 0 20pt" class="vertical-wrapper">
                    <div></div>
                    <svg viewBox="0 0 20 20" margin="auto">
                        <circle cx="10" cy="10" r="2.5" :fill="s.color"/>
                    </svg>
                    <div></div>

                </div>
                <div class="vertical-wrapper" style="margin-right: 20pt">
                    <div class="horizontal-wrapper">
                        <div style="flex: 1 0 auto">
                            <h4 style="margin-bottom: 0; flex: 1 0 auto; font-weight: 400;">{{ s.username }}:</h4>
                        </div>
                        <div style="flex: 3 0 0 ">
                            <h4 style="margin-bottom: 0; flex: 3 0 0; font-weight: 800; text-align: left;"> {{s.name }}</h4>
                            <h5 style="margin-top : 0; text-align: left;">{{formatTime(s.start)}} - {{formatTime(s.end)}}</h5>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <md-button class="md-fab md-primary md-invert" style="position: absolute; right: 5px; bottom: 65px; 
            height: 35pt; width: 35pt;" v-on:click="addEvent">
            <md-icon :md-src="require('../../assets/plus-solid.svg')" style="fill: white;" ></md-icon>
        </md-button>
        <router-view></router-view>
    </div>
</template>
<script>
import DateHeader from '../../components/date-header.vue'
//import hourlySchedule from '../../components/hourly-schedule/hourly-schedule.vue'

export default {
    name: 'events-view',
    components: {
        DateHeader
        //'hourly-schedule': hourlySchedule
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        
        formatedDate(){
            return this.date.format('YYYY MMMM DD')
        },
        date(){
            return this.$store.getters.getDate
        }
    },
    methods:{
        changeDate(days){
            this.$store.commit('set-date', this.date.add(days, 'days'))
            this.$forceUpdate()

        },
        reloadEvents(){
            console.log('reload events', this.date.format('YYYY-MM-DD'));
            this.$socket.emit('get-events', { token: this.token, date: this.date.format('YYYY-MM-DD') })

        },
        touchStartMethod(touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), { once: true });
            //addEventListener('touchmove', (touchEvent) => this.touchMove(touchEvent, posXStart));

        },
        touchMove(touchEvent, posXStart){
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXCurrrent = touchEvent.changedTouches[0].clientX;
            const diffXPos = posXCurrrent - posXStart; 
            if(!Number.isNaN(diffXPos) && Math.abs(diffXPos) > 50)
                this.marginCalendar = diffXPos
            console.log(Number.isNaN(diffXPos), diffXPos);
        },
        touchEnd(touchEvent, posXStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            this.marginCalendar = 0
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd && Math.abs(posXStart - posXEnd) > 50) {
                this.changeDate(-1)
            } else if (posXStart > posXEnd && Math.abs(posXStart - posXEnd) > 50) {
                this.changeDate(1)


            }
        },
        formatTime(time) {

            const d = this.$moment(this.date.format('YYYY-MM-DD'))
            //const [hours, minutes] = this.value.split(':').map(e => parseInt(e))
            const days = parseInt(time / 1440)
            d.add(days, 'days')

            const value = Math.abs(time) % 1440
            d.set('hour', parseInt((value) / 60))
            d.set('minute', value % 60)


            return d.format(days!=0? 'DD. HH:mm': 'HH:mm')
        },


        addEvent(){
            this.$router.push({ name: 'event-popup-view' })
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$socket.emit('get-events', {token: vm.token, date: vm.date})
            
            vm.$socket.emit('get-heating-plans', { token: vm.token })

        })
    },
    sockets:{
        'set-events':function({events}){
            events.forEach(e => e.key = this.$uuid.v4())
            this.schedule = events
        }
    },
    data(){
        return{
            marginCalendar: 0,
            schedule: [
              
            ]
        }
    }
}
</script>

<style>
.vdatetime.hide-input input{
    display: none;
}
</style>