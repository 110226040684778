<template>
    <div class="selection-list vertical-wrapper" > 
        <div v-if="topSelectionAll" class="list-item horizontal-wrapper" style="border-bottom: solid 1pt #2c3e50; flex: 0 0 auto;">
            <md-field v-if="searchBar" style="flex: 0 0 auto; flex: 0 0 0; margin: 5pt 0;">
                <label>Suchen</label>
                <md-input v-model="searchText" ></md-input>
            </md-field>

            <div class="placeholder"></div>
            <md-switch class="md-primary" @change="e => list.forEach(i => i.active = e)" v-model="forAllItems"></md-switch>
        </div>
        <div class="items-list" style=" flex: 1 0 0;">
            <div v-for="(item, i) in list.filter(e => ((e.name)? e.name.includes(searchText):false) || ((e.text)? e.text.includes(searchText): false))" :reload="reload"
                :key="item.key" 
                class="list-item horizontal-wrapper">

                <span style="margin: auto 5pt; flex: 0 0 auto; min-width: 50pt; cursor: pointer" :class="{'hide':item.edit}" v-on:click="goedit(item, i)">{{item.name || item.text}}</span>
                <input style="margin: auto 5pt; flex: 0 0 auto; min-width: 50pt" :class="{'hide':!item.edit}" v-model="item.name" ref="name-input" @keyup.enter="deselect(item, i)" @blur="deselect(item, i)"/>
                
                <div class="placeholder" v-on:click="goedit(item, i)"></div>
                <md-switch class="md-primary" @change="$emit('item-selection-change', list)"  v-model="item.active"></md-switch>
            </div>
            <div v-if="editable" class="horizontal-wrapper" >
                <div></div>
                <md-button class="md-primary" v-on:click="$emit('add-item')">add</md-button>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name:'selection-list',
    props: {'items':Array, 'top-selection-all':Boolean, 'search-bar':Boolean, 'editable':{type: Boolean, default: false} },
    watch: { 
        'items': function(items) { 
          items.forEach(e => {e.key = uuidv4(); e.edit = false })
          this.list = items
        }
    },
    methods: {
        uuidv4:uuidv4,
        goedit(item, i){
            if(!this.editable)
                return
                
            this.list.forEach(e => { e.edit = false })
            this.list[i].edit = true;
            item.edit = true
            this.reload ++
            this.$refs['name-input'][i].focus()

          
        },
        deselect(){
            this.list.forEach(e => { e.edit = false })
            this.reload ++

        }
      
    },
    data(){
        this.$props.items.forEach(e => {e.key = uuidv4(); e.edit = false})
        
        return{
            list: this.$props.items,
            forAllItems: false,
            searchText: '',
            reload: 0
        }
    }
}
</script>
<style>
.list-item .md-switch.md-primary.md-theme-default{
    flex:  0 0 auto;
}
.selection-list .items-list{
    overflow-y: scroll;
    /*min-height: 200pt;*/
}
@media only screen and (max-width: 768px) {
    .selection-list .items-list{
        overflow-y: scroll;
    }
}
</style>