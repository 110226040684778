<template>
    <div class="vertical-wrapper" style="margin: 3.5%;">
        <h2>Passwort vergessen</h2>
        <md-field style="flex: 0 0 auto">
            <label for="">E-Mail</label>
            <md-input v-model="email"  v-on:keyup.enter="forgotPassword"></md-input>
        </md-field>
        <md-button class="md-primary md-raised" style="margin: 0" v-on:click="forgotPassword" >Absenden</md-button>
    </div>
</template>
<script>
export default {
    name: 'forgot-password-view',
    methods:{
        forgotPassword(){
            this.$socket.emit('user-forgot-password', {email: this.email})
        }
    },
    sockets:{
        'user-forgot-password-feedback':function(body){
            this.$swal.fire(body)
        }
    },
    data(){
        return {
            email: ''
        }
    }
}
</script>