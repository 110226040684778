
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = function createStore() {
    let userToken = Vue.prototype.$cookies.get('user-token')
 
    if (!userToken)
        userToken = ''

    return new Vuex.Store({
        state: () => ({
            token: userToken,
            user: { id: -1, name: '' },

            date: Vue.prototype.$moment(),
            admin: 0,

            rooms: [],
            heatingPlans: {},

            selectedRoomId: 0,
            thermostatSetpoint: 0,

            thermostatMin: 0,
            thermostatMax: 100,

        }),
        mutations: {
            'set-token': function (state, token) {
                Vue.prototype.$cookies.set('user-token', token)
                state.token = token;

            },
            'set-admin': function (state, admin) {
                state.admin = admin
            },
            'set-rooms': function(state, rooms){
                state.rooms = rooms
            },
            'set-heating-plans': function(state, heatingPlans){
                state.heatingPlans = heatingPlans
            },
            'set-date':function(state, date){
                state.date = date
            },
            'set-selected-room-id': function(state, roomid){
                state.selectedRoomId = roomid;
            },
            'set-thermostat-setpoint': function(state, setpoint){
                state.thermostatSetpoint = setpoint;
            },
            'set-thermostat-min': function(state, value){
                state.thermostatMin = value
            },
            'set-thermostat-max': function (state, value) {
                state.thermostatMax = value
            }
        },
        actions: {
            'toggle-edit-mode': function ({ commit, state }) {
                commit('set-edit-mode', !state.editMode)
            }
        },
        getters: {
            token(state) {
                return state.token;
            },

            isadmin(state) {
                return state.admin
            },
            getRooms(state){
                return state.rooms
            },
            getHeatingPlans(state){
                return state.heatingPlans
            },
            getDate(state){
                return state.date
            },
            getSelectedRoomId(state){
                return state.selectedRoomId;
            }  ,
            getThermostatSetpoint(state){
                return state.thermostatSetpoint
            } ,

            getThermostatMin(state){
                return state.thermostatMin
            },
            getThermostatMax(state) {
                return state.thermostatMax
            }
         
        }
    })
}
export default store;