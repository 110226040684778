<template>
    <div class="horizontal-wrapper">
        <div>
            <h3>Mischkreislauf</h3>
            <h4 style="flex: 1 0 0">Aktiv: {{ hcActive }}</h4>
            <h4 style="flex: 1 0 0">Sollwert: {{ hcSet }}</h4>
            <h4 style="flex: 1 0 0">Istwert: {{ hcIs }}</h4>
        </div>
    
    </div>
</template>
<script>
export default{
    name: 'thermostat-control-view',
    computed: {
        token() {
            return this.$store.getters.token
        },
        date() {
            return this.$store.getters.getDate
        },
        isadmin() {
            return this.$store.getters.isadmin
        },
        selectedRoomId() {
            return this.$store.getters.getSelectedRoomId;
        }
    },
    sockets: {
        'set-thermostat-details-details': function (details) {
            let m = details.find(e => e.name == 'hcActive')
            if (m)
                this.hcActive = m.value

            m = details.find(e => e.name == 'hcSet')
            if (m)
                this.hcSet = m.value

            m = details.find(e => e.name == 'hcIs')
            if (m)
                this.hcIs = m.value

        },
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$socket.emit('get-thermostat-details', { token: vm.token, room: { id: vm.selectedRoomId }, date: vm.date })
        })
    },
    data(){
        return{

            hcActive: 0,
            hcIs: 0,
            hcSet: 0,
        }
    }
}
</script>