<template>
    <div>
        <md-dialog :md-active="show" style="">
            <div class="horizontal-wrapper" style="flex: 0 0 auto; ">
                <md-dialog-title style="display: inline">Raum </md-dialog-title>
                
                <md-dialog-actions>
                    <md-button class="md-primary" v-on:click="$router.push({name: 'settings-heating-plans-view'})">Close
                    </md-button>
                    <md-button class="md-primary" v-on:click="saveAndClose">Save</md-button>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="margin: 5pt;">
                <div>
                    <h4>Zeit</h4>
                    <div class="horizontal-wrapper" style="flex: 0 0 auto">
                        <md-field style="flex: 1 0 0; margin-right: 5pt;">
                            <label for="">Zeit</label>
                            <md-textarea v-model="timeRow" ref="timeRowInput" @focus="inputInFocus = 'time'"></md-textarea>
                        </md-field>
                        <div style="flex: 0 0 auto" v-on:click="e => $refs.datetimepicker.open(e)" class="vertical-wrapper">
                            <md-icon style="margin: auto" :md-src="require('../../assets/clock-regular.svg')" ></md-icon>
                        </div>
                    </div>
                    <div>
                        <md-chip v-for="c in options.filter(e => e.filter == 'time')" :key="c.key" md-clickable v-on:click="clickChip(c, 'time')">{{c.text}}</md-chip>
                    </div>
                </div>
                <datetime type="time" style="flex: 0 0 0" ref="datetimepicker" class="theme-green hide-input" v-model="isoFormatedDate" v-on:close="closeDateTime"></datetime>

                <div>
                    <h4>Temperatur</h4>
                    <div class="horizontal-wrapper" style="flex: 0 0 auto">
                        <md-field style="flex: 1 0 0; margin-right: 5pt;">
                            <label for="">Temperatur [°C]</label>
                            <md-textarea v-model="tempRow" ref="tempRowInput" @focus="inputInFocus = 'temp'"></md-textarea>
                        </md-field>
                      
                    </div>
                    <div>
                        <md-chip v-for="c in options.filter(e => e.filter == 'temperatur')" :key="c.key" md-clickable
                            v-on:click="clickChip(c, 'temp')">{{c.text}}</md-chip>
                    </div>
                </div>
                <div class="horizontal-wrapper" style="margin-top: 5pt">
                    <div></div>
                    <md-button class="md-raised md-accent" v-on:click="removeItem">delete</md-button>
                    <div></div>
                </div>
            </div>
        </md-dialog>
    </div>
</template>
<script>

export default {
    name: 'event-draft-room-popup',
    props: {
        'eventdraftid':Number,
        'roomid':Number,
        'tableRowIndex':Number,
    },
    computed: {
        token() {
            return this.$store.getters.token
        },
        eventDrafts(){
            return this.$store.getters.getHeatingPlans
        }
    },
    methods: {
        clickChip(c, addTo){
            if (addTo == 'time'){
                let cursorIndex = this.$refs['timeRowInput'].$el.selectionStart
                if(!cursorIndex)
                    cursorIndex = this.timeRow.length

                this.timeRow =  `${this.timeRow.substring(0, cursorIndex)} {${c.text}} ${this.timeRow.substring(cursorIndex)}`
            }else if(addTo == 'temp'){
                let cursorIndex = this.$refs['tempRowInput'].$el.selectionStart
                if (!cursorIndex)
                    cursorIndex = this.tempRow.length

                this.tempRow = `${this.tempRow.substring(0, cursorIndex)} {${c.text}} ${this.tempRow.substring(cursorIndex)}`
            }
        }, 
        closeDateTime(){
            const timeString = this.$moment(this.isoFormatedDate).format('HH:mm')
            let cursorIndex = this.$refs['timeRowInput'].$el.selectionStart
            if (!cursorIndex)
                cursorIndex = this.timeRow.length

            this.timeRow = `${this.timeRow.substring(0, cursorIndex)} '${timeString}' ${this.timeRow.substring(cursorIndex)}`
            
        },
        saveAndClose(){
            const ed = this.eventDrafts.find(e => e.id == this.eventdraftid)
            if(ed){
                const table = ed.rooms[this.roomid]
                if (table && ((this.tableRowIndex == undefined) || this.tableRowIndex < 0))
                    table.push({ id: -1, time: this.timeRow, temperatur: this.tempRow })
                else if(table && this.tableRowIndex >= 0){
                    table[this.tableRowIndex].time = this.timeRow;
                    table[this.tableRowIndex].temperatur = this.tempRow 
                }
                else if(!table)
                    ed.rooms[this.roomid] = [{ id: -1, time: this.timeRow, temperatur: this.tempRow }]
                
            }
            this.$router.push({ name: 'settings-heating-plans-view' })

        },
        async removeItem(){
            const {isConfirmed} = await this.$swal.fire({
                icon: 'warning',
                title: 'Bist du sicher?',
                text: 'Eintrag löschen',
                showDenyButton: true,
                showConfirmButton: true,

                confirmButtonText: 'JA',
                denyButtonText: 'nein',
                
            })
            if(!isConfirmed){
                return
            }
            const ed = this.eventDrafts.find(e => e.id == this.eventdraftid)
            if (ed) {
                const table = ed.rooms[this.roomid]
                if(table){
                    ed.rooms[this.roomid] = table.filter((e, i) => (i != this.tableRowIndex))
                }
                this.$router.push({ name: 'settings-heating-plans-view' })

            }    
        }

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.show = true
            const ed = vm.eventDrafts.find(e => e.id == vm.eventdraftid)
            if(ed){
                const table = ed.rooms[vm.roomid]
                if(vm.tableRowIndex >= 0){
                    vm.timeRow = table[vm.tableRowIndex].time
                    vm.tempRow = table[vm.tableRowIndex].temperatur

                }
            }
            next();
        })
    },
    data() {
        return {
            timeRow: '',
            tempRow: '',
            isoFormatedDate: this.$moment().format('YYYY-MM-DDTHH:mm:ssZ'),

            inputInFocus: '',

            show: false,
            active: false,


            options: [
                { text: 'Start Punkt', key: this.$uuid.v4(), filter: 'time' },
                { text: 'End Punkt', key: this.$uuid.v4(), filter: 'time' },

                { text: 'Besucheranzahl', key: this.$uuid.v4(), filter: 'time' },
                { text: 'Besucheranzahl', key: this.$uuid.v4(), filter: 'temperatur' },

                { text: 'Raumgröße', key: this.$uuid.v4(), filter: 'temperatur' },
                { text: 'Außentemperatur', key: this.$uuid.v4(), filter: 'temperatur' },
            ]

        }
    }
}
</script>
<style>
.vertical-wrapper > div.row{
    flex: 0 0 auto;

}
div.row:hover{
    background-color: #dddddd;
}
div.row.edit:hover{
    background-color: white;
}
div.row{
    border-bottom: #b1b1b1 1px solid;
}
.row > span{
    cursor: pointer;

    flex: 1 0 0;
    text-align: center;
    margin: 15pt 0;
}
</style>