<template>
    <div class="vertical-wrapper">
        <div style="flex: 1 0 0" class="horizontal-wrapper">
            <div class="icon logo">

            </div>
        </div>
        <h3 style="font-size: 30pt; margin-bottom: 10pt">
            <span style="color: #03ac13">Heating </span>
            <span>Plan</span>
            <span style="color: #03ac13">'t</span>
            
        </h3>
        <div class="horizontal-wrapper md-navbar" style="flex: 0 0 auto; margin: 0 5pt; margin-top: 10px;">
            <router-link :to="{name: 'login-view'}" class="item">Login</router-link>
            <router-link :to="{name: 'register-view'}" class="item">Register</router-link>

        </div>
        <router-view></router-view>
        <div style="flex: 1 0 0">
        
        </div>
    </div>
</template>
<script>
export default {
    name: 'start-view'
}
</script>
<style>

</style>