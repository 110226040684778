<template>
    <div class="vertical-wrapper" style="margin: 0 10%;">
        <h3>Passwort zurücksetzen</h3>
        <md-field>
            <label for="">neues Passwort</label>
            <md-input type="password" v-model="pw0"  v-on:keyup.enter="resetPassword"></md-input>
        </md-field>
        <md-field>
            <label for="">neues Passwort wiederholen</label>
            <md-input type="password" v-model="pw1"  v-on:keyup.enter="resetPassword"></md-input>
        </md-field>
        <div style="flex:0 0 auto" class="vertical-wrapper">
            <md-button class="md-primary md-raised" style="margin: 0" v-on:click="resetPassword">zurücksetzen</md-button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reset-password-view',
    props: {token: String},
    methods: {
        resetPassword(){
            if(this.pw0 != '' && this.pw0 == this.pw1)
                this.$socket.emit('user-reset-password', {token: this.token, password: this.pw0})
            else
                this.$swal.fire({icon: 'error', title: 'Fehler', text: 'Passwörter müssen gleich sein'})
        }
    },data(){
        return{
            pw0: '', pw1: ''
        }
    },
    sockets:{
        'user-reset-password-success':function(){
            this.$swal.fire({title: 'Erfolg', icon: 'success', text: 'Passwort zurückgesetzt, melden Sie sich erneut an'})
        }
    }
}
</script>