<template>
    <div class="vertical-wrapper">
        <setting-header title="User" :route="{name: 'settings-menu-view'}" />

        <div class="vertical-wrapper" style="margin: 0 5pt">
            <md-field style="flex: 0 0 auto">
                <label for="">Name</label>
                <md-input v-model="name"></md-input>
            </md-field>

            <md-field style="flex: 0 0 auto">
                <label for="">E-Mail</label>
                <md-input v-model="email"></md-input>
            </md-field>

            <md-switch v-model="changePw" class="md-primary" style="flex: 0 0 auto">Passwort ändern</md-switch>
            <div v-if="changePw" class="vertical-wrapper" >
                <md-field style="flex: 0 0 auto">
                    <label for="">Passwort</label>
                    <md-input type="password" v-model="pw"></md-input>
                </md-field>
                <md-field style="flex: 0 0 auto">
                    <label for="">Passwort wiederholen</label>
                    <md-input type="password" v-model="pw1"></md-input>
                </md-field>
            </div>

            <md-button class="md-primary md-raised" v-on:click="logout">Abmelden</md-button>
        </div>

    </div>
</template>
<script>
import settingHeaderVue from '../../components/setting-header.vue';

export default {
    name: 'settings-user-view',
    components: {
        'setting-header': settingHeaderVue
    },
    computed:{
        token(){
            return this.$store.getters.token
        }
    },
    methods:{
        logout(){
            this.$store.commit('set-token', '')
            this.$router.push({ name: 'login-view'})
        }
    },
    sockets:{
        'set-user-details':function({email, name}){
            this.email = email;
            this.name = name;
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$socket.emit('get-user-details', {token: vm.token})
        })
    },
    data(){
        return{
            name: '',
            email: '',

            changePw: false,
            pw: '', pw1: ''
        }
    }
}
</script>