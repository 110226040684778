<template>
    <div class="horizontal-wrapper" style="flex: 0 0 auto">
        <div class="icon"
            :style="`flex: 0 0 16pt; margin-left: 5pt; margin-right:20pt; background-image: url(${require('../assets/controls/angle-left-solid.svg')})`"
            v-on:click="changeDate(-1)">
        </div>
        <h3 style="flex: 1 0 0" v-on:click="e => $refs.datetimepicker.open(e)">
            {{date? date.locale("de").format('ddd DD. MMMM YYYY'): ''}}
        </h3>
        <div class="icon"
            :style="`flex: 0 0 16pt; margin-right: 5pt; margin-left: 20pt; background-image: url(${require('../assets/controls/angle-right-solid.svg')})`"
            v-on:click="changeDate(1)">
        </div>
        <datetime style="flex: 0 0 0" ref="datetimepicker" class="theme-green hide-input" v-model="isoFormatedDate"></datetime>

    </div>
</template>
<script>

export default {
    name: 'date-header',
    computed:{
        date(){
            return this.$store.getters.getDate
        }, 
        isoFormatedDate: {
            set(e) {
                const date = this.$moment(e).format('YYYY-MM-DD ')
                const time = this.$moment().format('HH:mm')
                this.$store.commit('set-date', this.$moment(date + time))
                this.$nextTick(() => {

                    this.$emit('change')
                })

            },
            get() {
                return this.date.format('YYYY-MM-DDTHH:mm:ssZ')
            }
        }
    },
    methods:{
        changeDate(days){
            this.$store.commit('set-date', this.$moment(this.date).add(days, 'days'))
            this.$emit('change')
        }
    },
    data(){
        return {
          
        }
    } 

}
</script>