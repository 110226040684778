<template>
    <div class="vertical-wrapper">
        <settings-header title="Heizräume" :route="{name:'settings-menu-view'}"/>
        <div v-if="rooms.length" class="vertical-wrapper" style="overflow-y: scroll; margin: 0 5pt; ">
            <md-field style="flex: 0 0 auto">
                <label for="">Raum</label>
                <md-select v-model="selectedRoomKey" v-on:selected="changedRoom">
                    <md-option v-for="r in rooms" :key="r.key" :value="r.key">{{r.name}}</md-option>
                </md-select>
            </md-field>

            <md-field style="flex: 0 0 auto">
                <label for="">Name</label>
                <md-input v-model="name"></md-input>
            </md-field>
            
            <md-field style="flex: 0 0 auto" ref="temp-input">
                <label for="">Normal Temperatur [°C]</label>
                <md-input v-model="normalTemperatur"></md-input>
            </md-field>
            <md-field style="flex: 0 0 auto" ref="temp-input">
                <label for="">Maximal Temperatur [°C]</label>
                <md-input v-model="maxTemperatur"></md-input>
            </md-field>
            <md-field style="flex: 0 0 auto" ref="temp-input">
                <label for="">Minimal Temperatur [°C]</label>
                <md-input v-model="minTemperatur"></md-input>
            </md-field>

            <h5 class="subheader">User</h5>
            <md-selection-list style="margin-right: 70px" :items="user" :editable="false" ></md-selection-list>

            

        </div>
        <div v-else style="flex: 1 0 0" class="vertical-wrapper">
            <div></div>
            <h2 class="empty-state">keine Heizpläne angelegt</h2>
            <div style="flex: 2 "></div>
        </div>
        <md-button class="md-fab md-primary md-invert" style="position: absolute; right: 5px; bottom: 65px; height: 35pt; width: 35pt;" v-on:click="addRoom">
            <md-icon :md-src="require('../../assets/plus-solid.svg')" style="fill: white;"></md-icon>
        </md-button>
    </div>
</template>
<script>
import settingHeaderVue from '../../components/setting-header.vue';

export default {
    name: 'settings-rooms-view',
    components: {
        'settings-header':settingHeaderVue
    },
    
    computed:{
        token() {
            return this.$store.getters.token
        },
        rooms(){
            const rooms = this.$store.getters.getRooms
            this.$nextTick(() => {
                if(rooms.length && this.opened && !this.newRoom){
                    this.selectedRoomKey = rooms[0].key
                }
            })
            return rooms
        },
        name:{
            get(){
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (!r)
                    return ''

                return r.name
            },
            set(value){
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (r)
                    r.name = value
            }
        },
       
        normalTemperatur: {
            get() {
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (!r)
                    return ''

                return r.attributes.normalTemperatur
            },
            set(value) {
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (r)
                    r.attributes.normalTemperatur = value
            }
        },
        maxTemperatur: {
            get() {
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (!r)
                    return ''

                return r.attributes.maxTemperatur
            },
            set(value) {
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (r)
                    r.attributes.maxTemperatur = value
            }
        },
        minTemperatur: {
            get() {
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (!r)
                    return ''

                return r.attributes.minTemperatur
            },
            set(value) {
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)
                if (r)
                    r.attributes.minTemperatur = value
            }
        },
        user: {
            get(){
                const r = this.rooms.find(e => e.key == this.selectedRoomKey)

                if (!r)
                    return []

                return r.users
            }
        },
        selectedRoomAttributes(){
            const r = this.rooms.find(e => e.key == this.selectedRoomKey)
            if(!r)
                return {}

            return r.attributes
        }
    },
    methods: {
        addRoom(){
            this.$swal.fire({ 
                icon: 'question',
                title: 'Raum hinzufügen?',
                
                showDenyButton: true,
                denyButtonText: 'Nein',

                showConfirmButton: true,
                confirmButtonText: 'Ja'
            }).then(e => {
                if (!e.isConfirmed)
                    return
                
                const newRoomKey = this.$uuid.v4()
                this.rooms.push({
                    id: -1, 
                    key: newRoomKey, 
                    name: `Raum ${this.rooms.length + 1}`, 
                    users: this.users,
                    attributes: {
                        normalTemperatur: 18,
                        maxTemperatur: 24,
                        minTemperatur: 5
                    }
                    
                })
                this.newRoom = true
                this.selectedRoomKey = newRoomKey
                
            })
        },
        changedRoom(){
            this.newRoom = false
            this.$nextTick(() => {

                this.$forceUpdate()
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.selectedRoomKey = -1
            vm.$socket.emit('get-rooms', {token: vm.token})
            vm.opened = true

        })
    },
    beforeRouteLeave(to, from, next){
        this.$socket.emit('edit-rooms', {token: this.token, rooms: this.rooms})
        
        next()
    },
    sockets: {
        'set-rooms-user':function({users}){
            users.forEach(e => { e.key = this.$uuid.v4(); e.active = false})
            this.users = users
        }
    },
    data(){
        return{
            opened: false,
            selectedRoomKey: -1,
            users: [],

            newRoom: false,

            attributes: [
                { 
                    text: 'Normal Temperatur [°C]', name: 'normalTemperatur', key: this.$uuid.v4(),
                },{
                    text: 'Maximal Temperatur [°C]', name: 'maxTemperatur', key: this.$uuid.v4(), 
                },{
                    text: 'Minimal Temperatur [°C]', name: 'minTemperatur', key: this.$uuid.v4(),
                 },

            ]
            
        }
    }
}
</script>