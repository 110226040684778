<template>
    <div class="vertical-wrapper">
        <settings-header title="Heizpläne" :route="{name:'settings-menu-view'}" />

        <div class="vertical-wrapper" style="margin: 0 5pt; overflow-y: scroll;">  
            <div v-if="eventDrafts.length" class="vertical-wrapper">

                <md-field>
                    <label for="">Heizplan</label>
                    <md-select v-model="selectedEventdraftId">
                        <md-option v-for="ed in eventDrafts" :key="ed.key" :value="ed.key">
                            {{ed.name}}
                        </md-option>
                    </md-select>
                </md-field>
                <div class="horizontal-wrapper" style="flex: 0 0 auto">

                    <md-field style="flex: 1 0 0 ">
                        <label for="">Name</label>
                        <md-input v-model="selectedEventdraft.name"></md-input>
                    </md-field>
                    <div style="flex: 0 0 auto">
                        <v-swatches v-model="selectedEventdraft.color"></v-swatches>
                    </div>
                </div>
                <md-tabs md-alignment="centered" style="background-color: #fafafa;">
                    <md-tab md-label="Tabelle" style="background-color: #fafafa;">
                        <div class="vertical-wrapper" >
                            <div v-for="r in rooms" :key="r.key"  style="flex: 0 0 auto; box-shadow: 0 2px 3px 0px rgb(0 0 0 / 20%); margin: 5px 4px; " :style="openRoomId == r.id? 'background-color: white;': ''"  >
                                <div class="horizontal-wrapper" v-ripple style="background-color: white;" v-on:click="openRoomId = openRoomId == r.id? 0: r.id">
                                    <h3 style="flex: 1 0 0"  >{{r.name}}</h3>
                                    <div class="icon greyout" :style="`background-image: url(${require('../../assets/caret-down-solid.svg')}); margin: 5pt; flex: 0 0 15pt; transition: transform .4s; transform: rotate(${180 * (openRoomId == r.id)}deg);`">

                                    </div>
                                </div>
                                
                                <div v-if="openRoomId == r.id" style="min-height: 200pt">
                                    <md-table>
                                            
                                        <md-table-row>
                                            <md-table-head>Zeit</md-table-head>
                                            <md-table-head>Temperatur</md-table-head>
                                        </md-table-row>
                                        
                                        <md-table-row 
                                            v-for="(r, index) in selectedEventdraft.rooms[openRoomId]" :key="r.key" 
                                            v-on:click="$router.push({name: 'event-draft-room-popup-view', params: { eventdraftid: selectedEventdraft.id, roomid: openRoomId, tableRowIndex: index }})">
                                            <md-table-cell>{{r.time}}</md-table-cell>
                                            <md-table-cell>{{r.temperatur}}</md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                        
                                </div>
                            </div>
                        </div>
                    </md-tab>
                    <md-tab md-label="Trend" style="background-color: #fafafa;">
                        
                    </md-tab>

                </md-tabs>

            </div>
              

            <div v-else style="flex: 1 0 0" class="vertical-wrapper">
                <div></div>
                <h2 class="empty-state">keine Heizpläne angelegt</h2>
                <div style="flex: 2 "></div>
            </div>
            <router-view></router-view>
            <md-button 
                class="md-fab md-primary md-invert" 
                style="position: absolute; right: 5px; bottom: 65px; height: 35pt; width: 35pt;" 
                :style="openRoomId > 0? 'background-color: orange;':''"
                 v-on:click="addEventDraft">
                <md-icon :md-src="require('../../assets/plus-solid.svg')" style="fill: white;"></md-icon>
            </md-button>
        </div>
    </div>
</template>
<script>
import VSwatches from 'vue-swatches'
import settingHeaderVue from '../../components/setting-header.vue';
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css"

export default {
    name: 'settings-heating-plans-view',
    computed:{
        token(){
            return this.$store.getters.token
        },
        rooms(){
            return this.$store.getters.getRooms
        },
        eventDrafts() {
            const eventDrafts = this.$store.getters.getHeatingPlans
            this.$nextTick(()=> {
                if (eventDrafts.length)
                    this.selectedEventdraftId = eventDrafts[0].key
            })
            return eventDrafts
        },
        selectedEventdraft(){
            const m = this.eventDrafts.find(e => e.key == this.selectedEventdraftId)
            if(!m)
                return {}

            return m
        }
    },
    components: {
        'settings-header': settingHeaderVue,
        VSwatches
    },
    sockets:{
        
    },
    methods:{
        addEventDraft(){
            if(this.openRoomId > 0){
                this.$router.push({name: 'event-draft-room-popup-view', params: { eventdraftid: this.selectedEventdraft.id, roomid: this.openRoomId, tableRowIndex: -1 }})
                return
            }

            this.$swal.fire({
                icon: 'question',
                title: 'Heizplan hinzufügen?',

                showDenyButton: true,
                denyButtonText: 'Nein',

                showConfirmButton: true,
                confirmButtonText: 'Ja'
            }).then(e => {
                if (!e.isConfirmed)
                    return

                const newKey = this.$uuid.v4()
                const newPlan = {
                    id: -1,
                    key: newKey,
                    name: `Plan ${this.eventDrafts.length + 1}`,
                    color: '',
                    rooms: this.rooms.reduce((arr, e) => {
                        arr[e.id] = []
                        return arr
                    }, {})

                }
                this.eventDrafts.push(newPlan)
                this.selectedEventdraftId = newKey

            })
        },
        addToHeatingPlan(){

        }  
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$socket.emit('get-rooms',{ token: vm.token})

            vm.$socket.emit('get-heating-plans',{ token: vm.token})
        })
    },
    beforeRouteLeave(to, from, next){
        this.$socket.emit('edit-heating-plans', {token: this.token, eventDrafts: this.eventDrafts});

        next();
    },
    data(){
        return {
            selectedEventdraftId: -1,

            openRoomId: 0,

            heatingplans: {
                1: [{ time: '{start} - "04:00"', temperatur: '23.5' }, { time: '{start} - "02:00"', temperatur: '22.5' }]
            }
        }
    }
}
</script>
<style>
.icon.greyout svg{
    fill: rgb(133, 132, 132);
}
</style>