<template>
    <div class="vertical-wrapper">
        <router-view>

        </router-view>
    </div>
</template>
<script>
export default {
    name: 'settings-view',
    computed:{
      
    }
}
</script>
<style>
header.settings-header .md-svg-loader.md-icon.md-icon-image.md-theme-default{
    margin: 2.5pt
}
header.settings-header .md-button{
    min-width: 30px;
}
header.settings-header{
    box-shadow: 0 2px 3px 0px rgb(0 0 0 / 20%);
    background-color: white;
}
</style>