<template>
    <div>
        <date-header :style="` display: flex `" v-on:change="changeDate"></date-header>
        
        <div :style="`flex: 1 0 0`">
            <div ref="width-reference"></div>
            <div class="chartbox" ref="chartbox" style="height: 270px; ">
        
            </div>
        </div>
    </div>
</template>
<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>

<script>
import dateHeader from '../../../components/date-header.vue';

google.charts.load('current', {'packages':['corechart', 'timeline'], 'language': 'de'});

export default{
    name: 'thermostat-chart-view',
    components: { 'date-header':dateHeader },

    computed:{
        token() {
            return this.$store.getters.token
        },
        date() {
            return this.$store.getters.getDate
        },
        isadmin() {
            return this.$store.getters.isadmin
        },
        selectedRoomId(){
            return this.$store.getters.getSelectedRoomId;
        }
    },
    methods:{
        changeDate() {
            this.$socket.emit('get-thermostat-details', { token: this.token, room: { id: this.selectedRoomId }, date: this.date })

        },
    },
    sockets: {
        'set-line-chart': function ({ dataTable }) {
            // console.log( dataTable.map((row, i) => i ? row.map((item, index) => (index) ? item : new Date(item)) : row));
            const data = new google.visualization.DataTable();
            data.addColumn('datetime', 'time');
            data.addColumn('number', 'Ist [°C]');
            data.addColumn({ type: 'string', title: 'Ist [°C]', role: 'tooltip', 'p': { 'html': true } })

            //data.addColumn({ type: 'number', title: 'Soll [°C]' });
            data.addColumn('number', 'Soll [°C]');

            data.addColumn({ type: 'string', title: 'Soll [°C]', role: 'tooltip', 'p': { 'html': true } })

            data.addRows(

                dataTable.map(e => [
                    new Date(e[0]),
                    e[1], e[1] ? `<p style="font-weight: 800; margin-bottom: 0">${this.$moment(new Date(e[0])).format('HH:mm')}</p><pstyle="font-weight: 400; margin: 0 5pt">Ist: ${e[1].toFixed(2)}°C</p>` : '',
                    e[2], e[2] ? `<p style="font-weight: 800; margin-bottom: 0">${this.$moment(new Date(e[0])).format('HH:mm')}</p><pstyle="font-weight: 400; margin: 0 5pt">Soll: ${e[2].toFixed(2)}°C</p><p>${e[3]}</p>` : '',
                ])
            )


            const chartbox = this.$refs.chartbox
            const options = {}
            options.crosshair = {
                trigger: 'both',
                orientation: 'vertical',
                color: '#fafafa',
            }
            options.legend = { position: 'bottom' }
            options.height = undefined;
            options.width = this.$refs['width-reference'].clientWidth;

            options.hAxis = {  gridlines: { color: '#fafafa' } }
            options.vAxis = { gridlines: { color: '#fafafa' } }

            options.backgroundColor = '#fafafa'
            options.chartArea = { left: 30, right: 0 }
            options.colors = ['#03ac13', 'orange']

            options.tooltip = { isHtml: true };
            const chart = new google.visualization.LineChart(chartbox);
            //google.visualization.events.addListener(chart, 'ready', this.chart_loaded);

            chart.draw(data, options);
        },
    },
    /*beforeRouteEnter(from, to, next){
        next(vm  => {
            vm.$socket.emit('')
        })
    },*/
    data(){
        return{

        }

    } 
}  
</script>