<template>
    <div class="vertical-wrapper">
        <settings-header title="Informationen" :route="{name:'settings-menu-view'}"/>
        <div class="vertical-wrapper" style="margin: 0 5pt">
            <h4>Made by</h4>

            <div class="icon"  :style="`flex: 0 0 77pt; background-image: url(${require('../../assets/frapp-logo-mit-schriftzug.svg')})`"></div>
            <h4>Ihre Anlagen voll vernetzt</h4> 
            
        </div>
    </div>
</template>
<script>
import settingHeaderVue from '../../components/setting-header.vue';

export default {
    name: 'settings-devices-view',
    components:{
        'settings-header':settingHeaderVue
    }
}
</script>