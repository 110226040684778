<template>
    <div class="event-popup-view">

        <md-dialog :md-active="show">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline">Event</md-dialog-title>
                
                    <md-dialog-actions>
                        <md-button class="md-primary" v-on:click="$router.replace({name: 'events-view'})">Close</md-button>
                        <md-button class="md-primary" v-on:click="saveAndClose">Save</md-button>
                    </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="margin: 5pt ">
                <md-field>
                    <label for="">Veranstaltungen</label>
                    <md-select v-model="selectedEventDraftId" :disabled="(eventId || eventId > 0)? true: false">
                        <md-option v-for="ed in eventDrafts" :key="ed.key" :value="ed.key">{{ed.name}}</md-option>
                    </md-select>
                </md-field>

                <h2>Veranstaltung</h2>
                <md-field>
                    <label for="">Name</label>
                    <md-input v-model="name"></md-input>
                </md-field>
                <div>
                    <event-input v-for="ea in selectedEventDraft.attributes" :key="ea.key" :type="ea.type" :name="ea.name" v-model="ea.value">

                    </event-input>
                </div>
                <div class="horizontal-wrapper">
                    <div></div>
                    <md-button class="md-accent md-raised" v-on:click="removeEvent">remove</md-button>
                    <div></div>

                </div>
            </div> 
        </md-dialog>
    </div>

</template>
<script>
import eventInputVue from '../../components/event-input.vue'
export default {
    name: 'event-popup-view',
    props: {
        'eventId':[String, Number]
    },
    components:{
        'event-input':eventInputVue
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        date(){
            return this.$store.getters.getDate
        },
        eventDrafts() {
            const eventDrafts = this.$store.getters.getHeatingPlans
            this.$nextTick(() => {
                if (eventDrafts.length)
                    this.selectedEventDraftId = eventDrafts[0].key
            })
            return eventDrafts
        },
        selectedEventDraft(){
            if (!this.eventDrafts)
                return {}

            const m = this.eventDrafts.find(e => this.selectedEventDraftId == e.key)
            if (!m)
                return {}

            return m
        }


    },
    methods:{
       saveAndClose(){
            const IDh_eventdraft = this.selectedEventDraft.id
            this.$socket.emit('edit-event', { token: this.token, date: this.date, event: { id: (this.eventId && this.eventId > 0)? this.eventId: -1, IDh_eventdraft, name: this.name, attributes: this.selectedEventDraft.attributes }})
            this.$router.replace({ name: 'events-view' })
        },
        removeEvent(){
            this.$swal({
                title: 'Entfernen?',
                text: 'Sie sind dabei ein Event zu entfernen',
                icon: 'warning',
                showDenyButton: true,
                denyButtonText: 'Nein',
                showConfirmButton: true,
                confirmButtonText: 'Ja',

            }).then(e => {
                if(e.isConfirmed){
                    this.$socket.emit('delete-event', {token: this.token, date: this.date, event: {id: this.eventId, }})
                    this.$router.replace({ name: 'events-view' })

                }
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.show = true
            vm.$socket.emit('get-heating-plans', { token: vm.token })

            vm.$socket.emit('get-event-plan', {token: vm.token, date: vm.date, event:{ id: vm.eventId }});
            if(vm.eventId)
                vm.$socket.emit('get-event-details', {token: vm.token, date: vm.date, event:{id: vm.eventId}})
            next();
        })
    },
    sockets: {
        'set-event-details':function({event, eventAttributes}){
            this.name = event.name
            const m = this.eventDrafts.find(e => e.id == event.IDh_event_draft)
            if(m)
                this.selectedEventDraftId = m.key
            this.$nextTick( ()=> {
                eventAttributes.forEach(eA => {
                    const m = this.selectedEventDraft.attributes.find(e => e.id==eA.id)
                    if(m)
                        m.value = eA.value
                })
            })
        }
    },
    data(){
        return{
            show: false,
            selectedEventDraftId: 0,
            name: ''
        }
    }
}
</script>