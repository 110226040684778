<template>
    <div class="event-popup-view">

        <md-dialog :md-active="show">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline">Organisation beitreten</md-dialog-title>
                
                    <md-dialog-actions>
                        <md-button class="md-primary" v-on:click="$router.push({name: 'settings-organisation-view'})">Close</md-button>
                        <md-button class="md-primary" v-on:click="addOrganisation">Save</md-button>
                    </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="margin: 5pt">
                <div style="flex: 0 0 30vh; margin-top: 20pt;">
                    <qrcode-stream @decode="onDecode" style="margin: auto; max-height: 30vh; width: 30vh"></qrcode-stream>
                </div>
                <md-field>
                    <label for="">Einladungsurl</label>
                    <md-input></md-input>
                </md-field>

                <h2 style="text-algin: center">{{organisation}}</h2>
            </div> 
        </md-dialog>
    </div>

</template>
<script>
import { QrcodeStream, } from 'vue-qrcode-reader'

export default {
    name: 'add-organisation-popup-view',
    props:{
        url: {
            type:String,
            default: ''
        }
    },
    components: {
        QrcodeStream
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        inputUrl:{
            get(){
                return (this.url != '')? this.url: this.inventationUrl
            },
            set(value){
                this.$socket.emit('get-organisation-info', {token: this.token, code: value})
                this.inventationUrl = value
            }
        }
    },
    methods:{
        onDecode(e){
            const chunks = e.split('/')
            const code = chunks[chunks.length - 1]

            this.inputUrl = code;
        },
        addOrganisation(){
            this.$socket.emit('add-user-to-organisation', {token: this.token, code: this.inputUrl})
            this.$router.go(-1)
        }
    },
    sockets:{
        'set-organisation-info':function({name}){
            this.organisation = name;
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.show = true
            if(vm.url != '')
                vm.$socket.emit('get-organisation-info', {token: vm.token, code: vm.url})
            next();
        })
    },
    data(){
        return{
            show: false,
            inventationUrl: '',
            organisation: ''
        }
    }
}
</script>