import VueRouter from 'vue-router'

import startView from '@/views/start-view.vue'
import homeView from '@/views/home-view.vue'

import loginView from '@/views/start/login-view.vue'
import registerView from '@/views/start/register-view.vue'
import forgotPassordView from '@/views/start/forgot-password-view.vue'



import thermostatView from '@/views/home/thermostat-view.vue'
import eventsView from '@/views/home/events-view.vue'
import settingsView from '@/views/home/settings-view.vue'

import eventPopupView from '@/views/popups/event-popup-view.vue'
import addOrganisationPopupView from '@/views/popups/add-organisation-popup-view.vue'
import heatingSegmentPopupView from '@/views/popups/heating-segment-popup-view.vue'


import settingsMenuView from '@/views/settings/menu-view.vue'
import settingsUserView from '@/views/settings/settings-user-view.vue'
import settingsOrganisationView from '@/views/settings/settings-organisation-view.vue'
import settingsDevicesView from '@/views/settings/settings-devices-view.vue'
import settingsRoomsView from '@/views/settings/settings-rooms-view.vue'
import settingsImpressumView from '@/views/settings/settings-impressum-view.vue'
import settingsHeatingPlansView from '@/views/settings/settings-heating-plans-view.vue'


export default new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', component: require('../views/home-page/index.vue').default },

        { path: '/start', name: 'start-view', redirect: { name: 'login-view'}, component:startView, children: [
            { path: '/start/login', name: 'login-view', component: loginView },
            { path: '/start/register', name: 'register-view', component: registerView },
            { path: '/start/forgot-password', name: 'forgot-password-view', component: forgotPassordView },
            { path: '/start/reset-password/:token?', name: 'reset-password-view', props:true, component: require('../views/start/reset-password-view.vue').default },

        ]},

        {
            path: '/home', name: 'home', component: homeView, children: [
                {
                    path: '/thermostat', name: 'thermostat-view', component: thermostatView, children: [
                    
                    { path: '/thermostat/control', name: 'thermostat-control-view', component: require('../views/home/thermostat-views/thermostat-control-view.vue').default },
                    { path: '/thermostat/chart', name: 'thermostat-chart-view', component: require('../views/home/thermostat-views/thermostat-chart-view.vue').default },
                    { path: '/thermostat/details', name: 'thermostat-details-view', component: require('../views/home/thermostat-views/thermostat-details-view.vue').default },
                ]
                },
                { path: '/events', name: 'events-view', component: eventsView, children:[
                    { path: '/event-popup/:eventId?', name: 'event-popup-view', props:true, component: eventPopupView },
                    ] 
                },
                {
                    path: '/settings', name: 'settings-view', component: settingsView, redirect: { name: 'settings-menu-view'}, children: [
                        { path: '/settings/menu', name: 'settings-menu-view', component: settingsMenuView },
                        { path: '/settings/user', name: 'settings-user-view', component: settingsUserView },
                        { path: '/settings/organisation', name: 'settings-organisation-view', component: settingsOrganisationView, children: [
                            { path: '/settings/organisation/add-organisation/:url?', name: 'add-organsiation-popup-view', props:true, component: addOrganisationPopupView},
                            ] 
                        },
                        { path: '/settings/rooms', name: 'settings-rooms-view', component: settingsRoomsView,  },

                        { path: '/settings/heating-plans', name: 'settings-heating-plans-view', component: settingsHeatingPlansView, props: true, children: [
                            { path: '/settings/heating-plans/rooms-details/:eventdraftid?/:roomid?/:tableRowIndex?', name: 'event-draft-room-popup-view', props: true, component: heatingSegmentPopupView },

                        ] },
                        { path: '/settings/devices', name: 'settings-devices-view', component: settingsDevicesView },

                        
                        { path: '/settings/impressum', name: 'settings-impressum-view', component: settingsImpressumView },
                    ] 
                },


            ]
           
        }, 
        


        { path: '*', template: '<p>404</P>' } // Not found
    ]
})