<template>
  <div id="app"  class="vertical-wrapper">
    <router-view>

    </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  sockets:{
   
    'login-success': function ({ token }) {
      this.$store.commit('set-token', token)
      //this.$store.commit('set-user', { id: userID })
      this.$router.push({ name: 'thermostat-view' }).then().catch()

    },
    'login-failed': function () {
      this.$router.push({ name: 'login-view' })

    },

    //Global Socketio Events

    'set-rooms': function ({ rooms, isAdmin }) {
      rooms.forEach(e => e.key = this.$uuid.v4())
      this.rooms = rooms
      this.$store.commit('set-rooms', rooms)
      
      if(this.rooms.length)
        this.$store.commit('set-selected-room-id', rooms[0].id)

      this.$store.commit('set-admin', isAdmin)

    }, 
    'set-heating-plans': function ({ eventDrafts }) {
      eventDrafts.forEach(e => e.key = this.$uuid.v4())
      this.$store.commit('set-heating-plans', eventDrafts)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 2px;
}
body{
  display: flex;
}
</style>
